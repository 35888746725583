export default function TopPlayerCount({ playerList }) {
    const topPlayerList = playerList.filter((element) => element.isTop100);

    return (
        <div className="topplayercount">
            <h3>Top Player Count</h3>
            <div className="topplayercount_count">{topPlayerList.length}</div>
        </div>
    );
}
