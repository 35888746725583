import { Box, Card, Typography, CardContent, Checkbox, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DinoDex from '../data/mydinodex.json';
import './dino.css';

const Dino = ({ dinoInfo, updateIsActive, onEditDino }) => {
    //console.log('Dino: ', dinoInfo);
    const completeDinoInfo = DinoDex[dinoInfo.id];

    const handleChange = (event, newValue) => {
        //console.log('handleChange', dinoInfo.id, newValue);
        updateIsActive(dinoInfo.id, newValue);
    };

    const handleClickEdit = (event) => {
        //console.log('handleClickEdit');
        onEditDino(dinoInfo);
    };

    return (
        <Card sx={{ boxShadow: 1, borderRadius: 2, maxWidth: 250 }}>
            <CardContent
                sx={{ display: 'flex', columnGap: '10px', paddingBottom: '15px !important' }}
            >
                <img
                    width="48"
                    height="58"
                    src={`https://roar.keltin.net/images/${dinoInfo.id}.png`}
                    alt={dinoInfo.id}
                />
                <Box sx={{ width: '100%' }}>
                    <Typography variant="body2">{completeDinoInfo.name}</Typography>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            //columnGap: '10px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Typography variant="body2">
                                <span className="boldit">H:</span> {dinoInfo.item.health}
                            </Typography>
                            <Typography variant="body2">
                                <span className="boldit">D:</span>
                                {dinoInfo.item.damage}
                            </Typography>
                            <Typography variant="body2">
                                <span className="boldit">S:</span>
                                {dinoInfo.item.speed}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4" sx={{ color: 'rebeccapurple' }}>
                                {/*<Box sx={{ color: 'rebeccapurple' }}>{dinoInfo.item.level}</Box>*/}
                                {dinoInfo.item.level}
                            </Typography>
                            <Typography variant="h6" sx={{ color: 'rebeccapurple', textAlign: 'right' }}>
                                {/*<Box sx={{ color: 'rebeccapurple', textAlign: 'right' }}>{dinoInfo.item.enhancements}</Box>*/}
                                {dinoInfo.item.enhancements}
                            </Typography>

                        </Box>
                    </Box>
                </Box>
                <div className="dino_actions">
                    <Checkbox
                        checked={dinoInfo.item.isActive}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {dinoInfo.item.isActive ? (
                        <IconButton
                            color="primary"
                            aria-label="edit dino"
                            onClick={handleClickEdit}
                        >
                            <EditIcon />
                        </IconButton>
                    ) : (
                        ''
                    )}
                </div>
            </CardContent>
        </Card>
    );
};
export default Dino;
