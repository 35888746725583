export default function Progress({ progressLog }) {
    const startIndex = progressLog.length > 10 ? progressLog.length - 10 : 0;

    const lastTen = progressLog.slice(startIndex);
    const log = lastTen.map((value, index) => (
        <li key={index}>
            {value.playerId} - {value.dinoId}
        </li>
    ));

    return (
        <div className="progresslog">
            <h3>Progress</h3>
            <ul>{log}</ul>
        </div>
    );
}
