import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

/*
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};*/

const names = ['Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday'];

export default function SelectDays({ disabledWeekdays, weekdays, setWeekdays }) {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        console.log('handleChange', value);
        setWeekdays(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    console.log('SelectDays', disabledWeekdays, weekdays);

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">Days</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    size="small"
                    value={weekdays}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    //MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            disabled={disabledWeekdays.indexOf(name) > -1 && !(weekdays.indexOf(name) > -1)}
                        >
                            <Checkbox
                                checked={weekdays.indexOf(name) > -1}
                                // Selected, but not by us
                                disabled={disabledWeekdays.indexOf(name) > -1 && !(weekdays.indexOf(name) > -1)}
                            />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
