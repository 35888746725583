import { useRouteError } from 'react-router-dom';
import './errorpage.css';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    if (error.status === 404) {
        console.log('NOT FOUND');
    }

    return (
        <div className="errorpage">
            <h1>Oops!</h1>
            {error.status === 404 ? (
                <p>I think he ate that page.</p>
            ) : (
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            )}
            <img width="700px" src="/error_image.jpeg" alt="404" />
        </div>
    );
}
