import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function PlayerSelector(props) {
    const { playerList, handleSelection, selectedPlayer } = props;

    //console.log('PlayerSelector', playerList);

    const handleOnChange = (event, selection) => {
        console.log('Selected Player: ', selection);
        handleSelection(selection);
    };

    // Convert passed in data to what the Autocomplete API needs ...
    const optionsList = [];
    playerList.forEach((playerInfo) => {
        optionsList.push({ name: playerInfo.name });
    });
    const selectedValue = selectedPlayer ? { name: selectedPlayer.name } : null;

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={optionsList}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Player" />}
            getOptionLabel={(option) => option.name}
            value={selectedValue}
            isOptionEqualToValue={(option, value) => {
                return option.name === value.name;
            }}
            onChange={handleOnChange}
        />
    );
}
