import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//import App, { loader as appLoader } from './App';
import AppLoader from './AppLoader';
import reportWebVitals from './reportWebVitals';
import './index.css';
import FirebaseLoginUI from './FirebaseLoginUI';
import ErrorPage from './ErrorPage';
import { auth } from './firebase.js';  // not actually used, just need it to initialize the firebase app
import WeeklyLoader from './weekly/WeeklyLoader';
import Homepage from './homepage/Homepage';
import Strats from './strats/Strats';

const router = createBrowserRouter([
    {
        path: '/',
        element: <FirebaseLoginUI />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/home',
        element: <Homepage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/strats',
        element: <Strats />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/buffs',
        element: <AppLoader />,
        //loader: appLoader,
        errorElement: <ErrorPage />,
    },
    {
        path: '/weekly',
        element: <WeeklyLoader />,
        errorElement: <ErrorPage />,
    }
]);

// Displayed StrictMode due to react-firebaseui issue
// https://github.com/firebase/firebaseui-web-react/issues/172
// <React.StrictMode>

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
        <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
