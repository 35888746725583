import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import './homepage.css';

export default function Homepage() {
    return (
        <div className="homepage_wrapper">
            <Link to="/weekly">
                <div className="homepage_box">
                    <Typography>Weekly Calendar</Typography>
                </div>
            </Link>
            <Link to="/strats">
                <div className="homepage_box">
                    <Typography>Stat Approvals</Typography>
                </div>
            </Link>
            <Link to="/buffs">
                <div className="homepage_box">
                    <Typography>Buffs!</Typography>
                </div>
            </Link>
        </div>
    );
}
