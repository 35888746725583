import React from 'react';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Segment from './Segment';
import './weekly.css';

export default function Weekly({ dinoList, dinoListByRarity }) {
    const [segments, setSegments] = React.useState([]);
    const addNewSegment = () => {
        const newSegment = {
            days: [],
            events: [{ attempts: 18, dinos: [] }],
        };
        setSegments(segments.concat(newSegment)); // concat creates a new array
    };
    const updateSegment = (index, segment) => {
        const updatedSegments = segments.slice();
        updatedSegments[index] = segment;
        setSegments(updatedSegments);
        console.log('Updated Segments', updatedSegments);
    };

    // combine all the selected days
    const allSelectedWeekdays = [];
    segments.forEach((segment) => {
        segment.days.forEach((day) => allSelectedWeekdays.push(day));
    });
    //console.log('All Selected Weekdays', allSelectedWeekdays);
    //console.log('Segments', JSON.stringify(segments, null, 2));

    const displaySegments = () => {
        return segments.map((segment, index) => (
            <Segment
                key={index}
                index={index}
                allSelectedWeekdays={allSelectedWeekdays}
                dinoList={dinoList}
                dinoListByRarity={dinoListByRarity}
                segment={segment}
                updateSegment={updateSegment}
            />
        ));
    };

    return (
        <div className="weekly_wrapper">
            <h1>Weekly Planning</h1>
            {displaySegments()}
            <div className="weekly_add">
                <Fab color="primary" aria-label="add" onClick={addNewSegment}>
                    <AddIcon />
                </Fab>
            </div>

            <div className="weekly_summary">
                <TextField
                    label="Weekly Summary"
                    disabled
                    fullWidth
                    multiline
                    rows={10}
                    value={JSON.stringify(segments)}
                />
            </div>
        </div>
    );
}
