import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { updateDinoInformation } from '../database';
import DinoSelector from './DinoSelector';
import {
    getHealthBoosts,
    getDamageBoosts,
    getSpeedBoosts,
    computeHealth,
    computeDamage,
    computeSpeed,
} from '../data/dinocalcs';
import DinoDex from '../data/mydinodex.json';
import './adddino.css';

function AddDino(props) {
    //console.log('AddDino.props(): ', props);
    const { dinoList, playerId, updateProgressLog, editingDinoInfo, clearEdit } = props;

    const levelRef = useRef(null);

    const [addingDino, setAddingDino] = useState(false);

    const [dinoInfo, setDinoInfo] = useState(null);
    const [dinoLevel, setDinoLevel] = useState('30');
    const [dinoEnhancements, setDinoEnhancements] = useState('0');
    const [dinoHealth, setDinoHealth] = useState('');
    const [dinoDamage, setDinoDamage] = useState('');
    const [dinoSpeed, setDinoSpeed] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const [healthBuffs, setHealthBuffs] = useState('0');
    const [damageBuffs, setDamageBuffs] = useState('0');
    const [speedBuffs, setSpeedBuffs] = useState('0');

    // When the user edits the stat, we use these functions to calculate the buff
    const calcHealthBuffs = () => {
        setHealthBuffs(dinoInfo && dinoHealth > 0 ? getHealthBoosts(dinoInfo.health, dinoLevel, dinoHealth, dinoEnhancements) : '');
    };
    const calcDamageBuffs = () => {
        setDamageBuffs(dinoInfo && dinoDamage > 0 ? getDamageBoosts(dinoInfo.damage, dinoLevel, dinoDamage, dinoEnhancements) : '');
    };
    const calcSpeedBuffs = () => {
        setSpeedBuffs(dinoInfo && dinoSpeed > 0 ? getSpeedBoosts(dinoInfo.speed, dinoSpeed, dinoEnhancements) : '');
    };

    // When the user edits the buff, we use these functions to calculate the stat
    const updateHealthBuffs = (param_buffs) => {
        const buffs = Number(param_buffs);
        setHealthBuffs(buffs);
        setDinoHealth(computeHealth(dinoInfo, dinoLevel, buffs, dinoEnhancements));
    };
    const updateDamageBuffs = (param_buffs) => {
        const buffs = Number(param_buffs);
        setDamageBuffs(buffs);
        setDinoDamage(computeDamage(dinoInfo, dinoLevel, buffs, dinoEnhancements));
    };
    const updateSpeedBuffs = (param_buffs) => {
        const buffs = Number(param_buffs);
        setSpeedBuffs(buffs);
        setDinoSpeed(computeSpeed(dinoInfo, buffs, dinoEnhancements));
    };

    const updateLevel = (param_level) => {
        const level = Number(param_level);
        setDinoLevel(level);

        // recalc stats based on the buffs previously assigned
        setDinoHealth(computeHealth(dinoInfo, level, healthBuffs, dinoEnhancements));
        setDinoDamage(computeDamage(dinoInfo, level, damageBuffs, dinoEnhancements));
    }
    const updateEnhancements = (param_enhancements) => {
        const enhancements = param_enhancements;
        setDinoEnhancements(enhancements);

        // re-calc all stats
        setDinoHealth(computeHealth(dinoInfo, dinoLevel, healthBuffs, enhancements));
        setDinoDamage(computeDamage(dinoInfo, dinoLevel, damageBuffs, enhancements));
        setDinoSpeed(computeSpeed(dinoInfo, speedBuffs, enhancements));
    }

    const handleClickAdd = () => {
        setAddingDino(true);
    };

    //console.log('editingDinoInfo', editingDinoInfo);

    // Should only run when we start (or stop) editing dino info ...
    useEffect(() => {
        console.log('editingUseEffect called');
        if (editingDinoInfo) {
            console.log('Starting edit of: ', editingDinoInfo);

            const dinoInfo = DinoDex[editingDinoInfo.id];
            setDinoInfo(dinoInfo);

            setDinoLevel(editingDinoInfo.item.level);
            setDinoEnhancements(editingDinoInfo.item.enhancements);
            setDinoHealth(editingDinoInfo.item.health);
            setDinoDamage(editingDinoInfo.item.damage);
            setDinoSpeed(editingDinoInfo.item.speed);

            // to remove complicated useEffect() dependencies, we are just going to dup a bit of code
            // from the calc functions above
            // calcHealthBuffs();
            // calcDamageBuffs();
            // calcSpeedBuffs();
            setHealthBuffs(getHealthBoosts(dinoInfo.health, editingDinoInfo.item.level, editingDinoInfo.item.health, editingDinoInfo.item.enhancements));
            setDamageBuffs(getDamageBoosts(dinoInfo.damage, editingDinoInfo.item.level, editingDinoInfo.item.damage, editingDinoInfo.item.enhancements));
            setSpeedBuffs(getSpeedBoosts(dinoInfo.speed, editingDinoInfo.item.speed, editingDinoInfo.item.enhancements));

            setAddingDino(true);
        }
        // else ... we can just ignore the else case, it's fine it if is empty
    }, [editingDinoInfo]);

    const handleSelectDino = (dinoSelectInfo) => {
        console.log('You selected this dino: ', dinoSelectInfo);
        const dinoInfo = DinoDex[dinoSelectInfo.id];
        setDinoInfo(dinoInfo);
        setDinoHealth(computeHealth(dinoInfo, dinoLevel, healthBuffs));
        setDinoDamage(computeDamage(dinoInfo, dinoLevel, damageBuffs));
        setDinoSpeed(computeSpeed(dinoInfo, speedBuffs));

        /*
            I seem to get on object and I set focus ... but it doesn't do anything ... 
            oh, it's passing me the div, not the input ... jeez
            */
        if (levelRef && levelRef.current) {
            console.log('Setting focus on Level??', levelRef.current);
            levelRef.current.focus();
        } else {
            console.log('wtf', levelRef);
        }
    };

    const validatePreSave = () => {
        setErrorMessage(null); // reset so they can try again

        if (!playerId) {
            setErrorMessage('No player selected!');
            return false;
        }
        if (!dinoInfo) {
            setErrorMessage('No dino selected!');
            return false;
        }
        if (dinoLevel < 10) {
            setErrorMessage('Dino Level too low!');
            return false;
        }
        if (dinoLevel > 30) {
            setErrorMessage('Dino Level too high!');
            return false;
        }
        if (dinoEnhancements < 0) {
            setErrorMessage('Dino Enhancements too low!');
            return false;
        }
        if (dinoEnhancements > 5) {
            setErrorMessage('Dino Enhancements too high!');
            return false;
        }
        if (!dinoHealth || dinoHealth < 0 || dinoHealth > 20000) {
            setErrorMessage('Invalid dino health');
            return false;
        }
        if (!dinoDamage || dinoDamage < 0 || dinoDamage > 5000) {
            setErrorMessage('Invalid dino damage');
            return false;
        }
        if (!dinoSpeed || dinoSpeed < 0 || dinoSpeed > 500) {
            setErrorMessage('Invalid dino speed');
            return false;
        }
        return true;
    };

    const saveNewDino = (e) => {
        e.preventDefault();

        if (!validatePreSave()) {
            return;
        }

        updateDinoInformation(playerId, dinoInfo.uuid, dinoHealth, dinoDamage, dinoSpeed, dinoLevel, dinoEnhancements);

        updateProgressLog(playerId, dinoInfo.uuid);

        handleClearData();
    };
    const handleClearData = () => {
        setDinoInfo(null);
        setDinoLevel('30');
        setDinoEnhancements('0');
        setDinoHealth('');
        setDinoDamage('');
        setDinoSpeed('');
        setHealthBuffs('');
        setDamageBuffs('');
        setSpeedBuffs('');
        setAddingDino(false);
        clearEdit();
    }

    return (
        <div className="adddino_wrapper">
            {!addingDino ? (
                <React.Fragment>
                    <h2>Add Dino</h2>
                    <Button variant="contained" component="label" onClick={handleClickAdd}>
                        <AddIcon />
                    </Button>
                </React.Fragment>
            ) : (
                <div className="adddino">
                    <div className="adddino_rows">
                        <div className="adddino_toprow">
                            {editingDinoInfo ? (
                                ''
                            ) : (
                                <DinoSelector dinoList={dinoList} handleSelection={handleSelectDino} />
                            )}
                            <div className="adddino_image">
                                {dinoInfo ? <img width="48" src={`https://roar.keltin.net/images/${dinoInfo.uuid}.png`} alt="" /> : ''}
                            </div>

                            {dinoInfo ? (
                                <div className="adddino_inputsection">
                                    <TextField
                                        ref={levelRef}
                                        //inputRef={input => console.log('wtf is this', input)}  // levelRef
                                        id="outlined-basic"
                                        InputProps={{
                                            type: 'number',
                                        }}
                                        label="Level"
                                        variant="outlined"
                                        style={{ margin: '0px 5px', width: '120px' }}
                                        size="small"
                                        value={dinoLevel}
                                        onChange={(e) => updateLevel(e.target.value)}
                                    />
                                    <TextField
                                        ref={levelRef}
                                        //inputRef={input => console.log('wtf is this', input)}  // levelRef
                                        id="outlined-basic"
                                        InputProps={{
                                            type: 'number',
                                        }}
                                        label="Enhancements"
                                        variant="outlined"
                                        style={{ margin: '0px 5px', width: '120px' }}
                                        size="small"
                                        value={dinoEnhancements}
                                        onChange={(e) => updateEnhancements(e.target.value)}
                                    />

                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {dinoInfo ? (
                            <div className="adddino_stats_wrapper">
                                <div className="adddino_stats">
                                    <TextField
                                        InputProps={{
                                            type: 'number',
                                        }}
                                        label="Health"
                                        variant="outlined"
                                        style={{ margin: '0px 5px' }}
                                        size="small"
                                        value={dinoHealth}
                                        onFocus={() => setHealthBuffs('')}
                                        onChange={(e) => setDinoHealth(e.target.value)}
                                        onBlur={calcHealthBuffs}
                                    />
                                    <TextField
                                        InputProps={{
                                            type: 'number',
                                        }}
                                        label="Damage"
                                        variant="outlined"
                                        style={{ margin: '0px 5px' }}
                                        size="small"
                                        value={dinoDamage}
                                        onFocus={() => setDamageBuffs('')}
                                        onChange={(e) => setDinoDamage(e.target.value)}
                                        onBlur={calcDamageBuffs}
                                    />
                                    <TextField
                                        InputProps={{
                                            type: 'number',
                                        }}
                                        label="Speed"
                                        variant="outlined"
                                        style={{ margin: '0px 5px' }}
                                        size="small"
                                        value={dinoSpeed}
                                        onFocus={() => setSpeedBuffs('')}
                                        onChange={(e) => setDinoSpeed(e.target.value)}
                                        onBlur={calcSpeedBuffs}
                                    />
                                    <div className="adddino_buffinfo">
                                        <TextField
                                            InputProps={{
                                                type: 'number',
                                            }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            label="Health Buffs"
                                            variant="outlined"
                                            style={{ margin: '0px 5px', width: '50%' }}
                                            size="small"
                                            value={healthBuffs}
                                            onChange={(e) => updateHealthBuffs(e.target.value)}
                                        />
                                    </div>
                                    <div className="adddino_buffinfo">
                                        <TextField
                                            InputProps={{
                                                type: 'number',
                                            }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            label="Damage Buffs"
                                            variant="outlined"
                                            style={{ margin: '0px 5px', width: '50%' }}
                                            size="small"
                                            value={damageBuffs}
                                            onChange={(e) => updateDamageBuffs(e.target.value)}
                                        />
                                    </div>
                                    <div className="adddino_buffinfo">
                                        <TextField
                                            InputProps={{
                                                type: 'number',
                                            }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            label="Speed Buffs"
                                            variant="outlined"
                                            style={{ margin: '0px 5px', width: '50%' }}
                                            size="small"
                                            value={speedBuffs}
                                            onChange={(e) => updateSpeedBuffs(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="adddino_btn">
                        <Button variant="contained" color="primary" fullWidth onClick={saveNewDino}>
                            Add
                        </Button>
                        <Button variant="contained" color="error" fullWidth onClick={handleClearData}>
                            Cancel
                        </Button>
                    </div>
                </div>
            )}

            {!errorMessage ? '' : <Alert severity="error">{errorMessage}</Alert>}
        </div>
    );
}
export default AddDino;
