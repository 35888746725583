import React from 'react';
import TextField from '@mui/material/TextField';
//import './homepage.css';

export default function Strats() {
    return (
        <div className="homepage_wrapper">
            <h1>Strat Approvals!</h1>
            <h2>Coming soon ...</h2>
        </div>
    );
}
