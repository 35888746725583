import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SelectDays from './SelectDays';
import DinoSelector from '../components/DinoSelector';
import DINOBASE from '../data/mydinodex.json';
import { TextField } from '@mui/material';

export default function Segment({ allSelectedWeekdays, dinoList, dinoListByRarity, index, segment, updateSegment }) {
    const updateWeekdays = (weekdays) => {
        const updatedSegment = Object.assign({}, segment, { days: weekdays });
        updateSegment(index, updatedSegment);
    };
    const addNewEvent = () => {
        const updatedSegment = Object.assign({}, segment);
        updatedSegment.events.push({ attempts: 42, dinos: [] });
        updateSegment(index, updatedSegment);
    };
    const handleSelectDino = (eventId, dinoInfo) => {
        //console.log('You selected this dino: ', eventId, dinoInfo);
        const updatedSegment = Object.assign({}, segment);
        const event = updatedSegment.events[eventId];
        event.dinos.push(dinoInfo.id);
        updateSegment(index, updatedSegment);
    };
    const handleChangeAttempts = (eventId, value) => {
        const updatedSegment = Object.assign({}, segment);
        const event = updatedSegment.events[eventId];
        event.attempts = value;
        updateSegment(index, updatedSegment);
    };

    const getDinoList = (prevDinos, numEvents) => {
        if (prevDinos.length === 0 || numEvents > 1) {
            return dinoListByRarity.all;
        }
        const dinoInfo = DINOBASE[prevDinos[0]];
        return dinoListByRarity[dinoInfo.rarity];
    };

    const displayEvents = () => {
        return segment.events.map((event, index) => (
            <div className="segment_events" key={index}>
                <TextField
                    id="outlined-basic"
                    size="small"
                    sx={{ width: '100px' }}
                    inputProps={{ style: { textAlign: 'center' } }}
                    label="Attempts"
                    variant="outlined"
                    value={event.attempts}
                    onChange={(event) => {
                        handleChangeAttempts(index, event.target.value);
                    }}
                />
                {displayDinos(event.dinos)}
                <DinoSelector
                    key={event.dinos.length + 1}
                    //dinoList={dinoList}
                    dinoList={getDinoList(event.dinos, segment.events.length)}
                    handleSelection={(dinoInfo) => handleSelectDino(index, dinoInfo)}
                />
            </div>
        ));
    };
    const displayDinos = (dinos) => {
        return dinos.map((dinoId, index) => {
            const dinoInfo = DINOBASE[dinoId];
            return (
                <div key={index} className="segment_dino">
                    <img
                        className=""
                        width="48"
                        height="58"
                        src={`https://roar.keltin.net/images/${dinoId}.png`}
                        alt={dinoId}
                    />
                    {dinoInfo.name}
                </div>
            );
        });
    };
    return (
        <div className="segment_wrapper">
            <div className="segment_topline">
                <SelectDays
                    disabledWeekdays={allSelectedWeekdays}
                    weekdays={segment.days}
                    setWeekdays={updateWeekdays}
                />
                <div className="weekly_add">
                    <Fab color="primary" aria-label="add" size="small" onClick={addNewEvent}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>

            {displayEvents()}
        </div>
    );
}
