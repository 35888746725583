import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDK0PX7wR0JGpNyQ-xDoeKmG4N_S5m6PV4',
    authDomain: 'dinomaster-d7f81.firebaseapp.com',
    projectId: 'dinomaster-d7f81',
    storageBucket: 'dinomaster-d7f81.appspot.com',
    messagingSenderId: '777811361847',
    appId: '1:777811361847:web:d1307210b4574ea2a1bfb2',
    measurementId: 'G-NZE7MGL7WB',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(app);
//const auth = app.auth();

export { app, db };
