// This is "higher order container" design to load the firebase data into the props off the app,
// so that if/when that data changes, we re-render the app.  (We also make sure to load it
// all so that we have it when we render.)

import DINODEX from '../data/mydinodex.json';
import Weekly from './Weekly';

// Total dupe of AppLoader, should probably parametarize, but whatever.
function WeeklyLoader() {

    const searchbarDinoList = [];
    const searchListsByRarity = {
        all: [],
        apex: [], unique: [], legendary: [], epic: [], rare: [], common: [], omega: []
    }
    Object.keys(DINODEX).forEach((dinoId) => {
        searchbarDinoList.push({ id: dinoId, name: DINODEX[dinoId].name });

        const dinoInfo = DINODEX[dinoId];
        searchListsByRarity.all.push({ id: dinoId, name: dinoInfo.name });
        searchListsByRarity[dinoInfo.rarity].push({ id: dinoId, name: dinoInfo.name });
    });

    return <Weekly dinoList={searchbarDinoList} dinoListByRarity={searchListsByRarity}/>;
}

export default WeeklyLoader;
