
// https://stackoverflow.com/questions/15313418/what-is-assert-in-javascript
/*

All of the params should really be converted to numbers when they leave the UI, but 
that isn't how it works, and the DB is now in strings, so it's a mess.  I don't have
time to clean it right now.

function assert(condition, message) {
    if (!condition) {
        throw new Error(message || "Assertion failed");
    }
}
*/

/**
 * Big Assumption:  User must enter enhancements first.
 * 
 * If they don't, and just enter a health and damage, it looks like they have extra buffs.
 * If you add enhancements on top of that, it grows even more - no way to know to "take them off".
 * So fill out Enhancements first, then the rest of the stats ...
 */

export function getHealthBoosts(param_baseHealth, param_level, param_health, param_enhancements) {
    /*assert(typeof baseHealth === 'number', 'baseHealth should be a Number');
    assert(typeof level === 'number', 'level should be a Number');
    assert(typeof health === 'number', 'health should be a Number');*/
    const baseHealth = Number(param_baseHealth);
    const level = Number(param_level);
    let health = Number(param_health);
    const enhancements = Number(param_enhancements);

    if (enhancements >= 1) {
        health = Math.round(health / 1.1);
    }

    const baseHealthAtLevel = baseHealth * Math.pow(1.05, level - 26);
    const numBoosts = (health - baseHealthAtLevel) / (baseHealthAtLevel * 0.025);
    console.log('getHealthBoosts', {baseHealth, level, health, enhancements, baseHealthAtLevel, numBoosts});
    return Math.round(numBoosts);
};
export function getDamageBoosts(param_baseDamage, param_level, param_damage, param_enhancements) {
    const baseDamage = Number(param_baseDamage);
    const level = Number(param_level);
    let damage = Number(param_damage);
    const enhancements = Number(param_enhancements);

    if (enhancements >= 2) {
        damage = Math.round(damage / 1.1);
    }

    const baseDamageAtLevel = Math.round(baseDamage * Math.pow(1.05, level - 26));
    return Math.round((Number(damage) - baseDamageAtLevel) / (baseDamageAtLevel * 0.025));
};
export function getSpeedBoosts(param_baseSpeed, param_speed, param_enhancements) {
    const baseSpeed = Number(param_baseSpeed);
    let speed = Number(param_speed);
    const enhancements = Number(param_enhancements);

    if (enhancements >= 3) {
        speed -= 2;
    }

    return Math.round((Number(speed) - baseSpeed) / 2);
};


export function computeHealth(dinoInfo, param_level, param_buffs, param_enhancements) {
    const level = Number(param_level);
    const buffs = Number(param_buffs);
    const enhancements = Number(param_enhancements);

    const baseHealth = dinoInfo.health;
    const baseLevelHealth = baseHealth * Math.pow(1.05, level - 26);
    const buffHealth = baseLevelHealth * (0.025 * buffs);
    //console.log('computeHealth', dinoInfo.name, level, buffs, baseHealth, baseLevelHealth, buffedLeveledHealth);

    let result = baseLevelHealth + buffHealth;
    if (enhancements >= 1) {
        result = result * 1.1;
    }

    return Math.trunc(result);
}

export function computeDamage(dinoInfo, param_level, param_buffs, param_enhancements) {
    const level = Number(param_level);
    const buffs = Number(param_buffs);
    const enhancements = Number(param_enhancements);

    const baseDamage = dinoInfo.damage;
    const baseLevelDamage = baseDamage * Math.pow(1.05, level - 26);
    const buffDamage = baseLevelDamage * (0.025 * buffs);

    //const buffedLeveledDamage = Math.round(baseLevelDamage + baseLevelDamage * (0.025 * buffs));

    let result = baseLevelDamage + buffDamage;
    //console.log('computeDamage', { name: dinoInfo.name, level, buffs, enhancements, baseDamage, baseLevelDamage, buffDamage, preEnhanced: result, enhance: result * 1.1 });
    if (enhancements >= 2) {
        result = result * 1.1;
    }

    return Math.trunc(result);
}

export function computeSpeed(dinoInfo, param_buffs, param_enhancements) {
    const buffs = Number(param_buffs);
    const enhancements = Number(param_enhancements);

    const baseSpeed = dinoInfo.speed;
    const buffedSpeed = baseSpeed + (2 * buffs);

    let result = buffedSpeed;
    if (enhancements >= 3) {
        result += 2;
    }

    return result;
}

