import { db } from './firebase.js';
import { collection, onSnapshot, query, doc, serverTimestamp, setDoc, updateDoc, getDocs } from 'firebase/firestore';


// See AppLoader to make sense of this
let __updatePlayerList = null;
let __updateDinoList = null;
export const initData = (updatePlayerList, updateDinoList) => {

    // hack to stop the react dev double calls
    if (__updatePlayerList !== null) {
        console.error('Avoid server call.  This is not the way to solve this problem.');
        return;
    }

    __updatePlayerList = updatePlayerList;
    __updateDinoList = updateDinoList;

    retrievePlayerList();
    retrieveDinoList();
};
export let firebaseError = null;

let PlayerList = [];
const retrievePlayerList = () => {
    onSnapshot(
        collection(db, 'players'),
        (snapshot) => {
            console.log('onSnapshot(players) updated');
            // We are leaving this as a snapshot, because when we add players or mark them top100,
            // we need to update the player list, just as easy to let firebase update it for us,
            // rather than try to dynamically jiggy the data

            // reset the PlayerList when we retrieve it, so we don't end up with a bunch of duplicates
            PlayerList = [];

            snapshot.forEach(function (doc) {
                const data = doc.data();
                PlayerList.push({ name: doc.id, isTop100: data.isTop100 });
            });

            console.log(PlayerList);
            __updatePlayerList(PlayerList);
        },
        (error) => {
            console.error('Error listening to player list: ', error);
            firebaseError = error;
            __updatePlayerList([]); // the by-product of this should be a render
        }
    );
};
const retrieveDinoList = () => {
    const q = query(collection(db, 'dinos'));
    getDocs(q).then(
        (querySnapshot) => {
            console.log('onSnapshot(dinos) updated');

            const DinoList = [];
            querySnapshot.forEach((doc) => {
                DinoList.push({ id: doc.id, name: doc.data().name });
            });
            __updateDinoList(DinoList);
        },
        (error) => {
            console.error('Error listening to dino list: ', error);
            firebaseError = error;
            __updateDinoList([]);
        }
    );
};

export const addNewPlayer = (playerName) => {
    const docPath = `players`;
    const newDocInfo = {
        isTop100: true, // if we are adding a player, let's presume that it is a top 100 player!
        timestamp: serverTimestamp(),
    };
    console.log('About to add doc: ', docPath, newDocInfo);

    // set the document, so either create it or fully reset its contents
    // since we don't really set any interesting information in the player, if someone
    // tries to add the same player again, it just updates the doc, no big deal.
    setDoc(doc(db, docPath, playerName), newDocInfo).catch((error) => {
        console.error(error);
    });

    const newPlayerInfo = Object.assign({}, newDocInfo, { name: playerName });
    PlayerList.push(newPlayerInfo);
    return newPlayerInfo;
};

export const updatePlayerTop100 = (playerName, isTop100) => {
    const docPath = `players`;
    const newDocInfo = {
        isTop100,
        timestamp: serverTimestamp(),
    };
    console.log('Updating top100 status to: ', docPath, newDocInfo);

    // let this just run asynchronously ...
    updateDoc(doc(db, docPath, playerName), newDocInfo)
        .then(() => {
            // ok, what we really need to do is update the player list now ...
            // which maybe begs the question of why fuck didn't I leave it as a snapshot?
        })
        .catch((error) => {
            console.error(error);
        });

    // meanwhile, add a copy of the player with the rgiht settings into the list, so that the UI 
    // doesn't complain ...
    /*const newPlayerInfo = Object.assign({}, newDocInfo, { name: playerName });
    PlayerList.push(newPlayerInfo);
    return newPlayerInfo;*/
};

export const updateDinoInformation = (playerId, dinoId, health, damage, speed, level, enhancements) => {
    const docPath = `players/${playerId}/dinos`;
    const newDocInfo = {
        health: health,
        damage: damage,
        speed: speed,
        level: level,
        enhancements: enhancements,
        isActive: true, // if we are editing the dino info, then by default it is active
        timestamp: serverTimestamp(),
    };
    console.log('About to add doc: ', docPath, newDocInfo);

    // set the document, so either create it or fully reset its contents
    setDoc(doc(db, docPath, dinoId), newDocInfo);
};

export const updateDinoIsActive = (playerId, dinoId, isActive) => {
    const docPath = `players/${playerId}/dinos`;
    const newDocInfo = {
        isActive: isActive,
        timestamp: serverTimestamp(), // ?  not sure we need this, but let's leave it
    };

    // just update these specific fields, leave everything else alone
    updateDoc(doc(db, docPath, dinoId), newDocInfo);
};
