import React from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';

export default function DinoSelector(props) {
    const { dinoList, handleSelection } = props;

    const handleOnChange = (event, selection) => {
        handleSelection(selection);
    };

    return (
        <Autocomplete
            disablePortal

            blurOnSelect
            //selectOnFocus
            //clearOnBlur

            size='small'
            options={dinoList}
            getOptionLabel={(option) => option.name}
            sx={{ width: 200 }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="30"
                        src={`https://roar.keltin.net/images/${option.id}.png`}
                        alt=""
                    />
                    {option.name}
                </Box>
            )}
            renderInput={(params) => <TextField autoFocus {...params} label="Dino" />}
            onChange={handleOnChange}
        />
    );
}
