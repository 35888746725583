import { Box, Card, Typography, CardContent, Checkbox } from '@mui/material';
import DinoDex from '../data/mydinodex.json';
import './dino.css';

const InactiveDino = ({ dinoInfo, updateIsActive }) => {
    //console.log('InactiveDino Info: ', dinoInfo);
    const completeDinoInfo = DinoDex[dinoInfo.id];

    const handleChange = (event, newValue) => {
        console.log('handleChange', dinoInfo.id, newValue);
        updateIsActive(dinoInfo.id, newValue);
    };

    return (
        <Card sx={{ backgroundColor: '#b2b0b0', boxShadow: 1, borderRadius: 2, width: 140 }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px', paddingBottom: '15px !important' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <img width="48" height="58" src={`https://roar.keltin.net/images/${dinoInfo.id}.png`} alt={dinoInfo.id} />
                    <Checkbox
                        checked={dinoInfo.item.isActive}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                <Typography variant="body2">{completeDinoInfo.name}</Typography>
            </CardContent>
        </Card>
    );
};
export default InactiveDino;
