import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PlayerNameRegex = /(\w)*#\d\d\d\d$/;

export default function FormDialog({ isOpen, onSave, onCancel }) {

    const [value, setValue] = useState('');
    const [helperText, setHelperText] = useState(null);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleOnSave = () => {

        if (value.match(PlayerNameRegex)) {
            setHelperText(null);
            onSave(value);
        } else {
            setHelperText('Invalid player name');
        }
    };

    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <DialogTitle>Add New Player</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="PlayerName#1234"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={handleChange}
                    error={helperText ? true : false}
                    helperText={helperText}

                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={handleOnSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
