// This is "higher order container" design to load the firebase data into the props off the app,
// so that if/when that data changes, we re-render the app.  (We also make sure to load it
// all so that we have it when we render.)

import { useState, useEffect } from 'react';
import { initData, firebaseError } from './database';
import DINODEX from './data/mydinodex.json';
import App from './App';

function AppLoader() {
    const [playerList, setPlayerList] = useState([]);
    const [dinoList, setDinoList] = useState([]);
console.log('APPLOADER!');
    useEffect(() => {
        initData(updatePlayerList, updateDinoList);
    }, []);

    const updatePlayerList = (newList) => {
        setPlayerList(newList);
    };
    const updateDinoList = (newList) => {
        console.log('updateDinoList!');
        console.log(JSON.stringify(newList, null, 2));
        setDinoList(newList);
    };

    const searchbarDinoList = [];
    Object.keys(DINODEX).forEach(dinoId => searchbarDinoList.push({ id: dinoId, name: DINODEX[dinoId].name}));

    return (
        <App
            playerList={playerList}
            //dinoList={dinoList}
            dinoList={searchbarDinoList}
            firebaseError={firebaseError}
        />
    );
}

export default AppLoader;
