import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Dino from './components/Dino';
import InactiveDino from './components/InactiveDino';
import { db } from './firebase.js';
import firebaselogout from './firebaselogout';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import PlayerSelector from './components/PlayerSelector';
import NewPlayerDialog from './components/NewPlayerDialog';
import AddDino from './components/AddDino';
import { updateDinoIsActive, addNewPlayer, updatePlayerTop100 } from './database';
import './App.css';
import Progress from './components/Progress';
import TopPlayerCount from './components/TopPlayerCount';

function App(props) {
    const { playerList, dinoList, firebaseError } = props;
    const navigate = useNavigate();

    const [newPlayerIsOpen, setNewPlayerIsOpen] = React.useState(false);
    const [playersDinos, setPlayersDinos] = useState([]);
    const [playerId, setPlayerId] = useState(null);
    const [progressLog, setProgressLog] = useState([]);

    const selectedPlayer = playerId
        ? playerList.find((element, index) => element.name === playerId)
        : null;

    // TODO:  not working?? ... might be working now that I removed props. ???
    if (firebaseError) {
        console.error('Throwing firebase error', firebaseError);
        throw firebaseError;
    }

    const handleSelectPlayer = (playerInfo) => {
        console.log('You selected player: ', playerInfo);
        if (!playerInfo) return; // during dev re-loading the UI can retain values we've lost within the component, creating some weird confusion
        setPlayerId(playerInfo.name);
    };
    const updateTop100Status = (event, newValue) => {
        updatePlayerTop100(playerId, newValue);
    };

    const updateProgressLog = (playerId, dinoId) => {
        const newProgress = [].concat(progressLog);
        newProgress.push({ playerId, dinoId });
        setProgressLog(newProgress);
        console.log('ProgressLog: ', newProgress);
    };
    const updateIsActive = (dinoId, isActive) => {
        updateDinoIsActive(playerId, dinoId, isActive);
    };

    // Everytime we change the playerId, we request their dinos.
    useEffect(() => {
        if (!playerId) {
            return;
        }
        console.log('App.js:useEffect()  /* load player dinos */')

        // AHA!  Dinos that don't have the timestamp field (older ones) are not returned by this query!
        const q = query(collection(db, `players/${playerId}/dinos`), orderBy('timestamp'));

        //onSnapshot(collection(db, `players/${playerId}/dinos`), (snapshot) => {
        onSnapshot(q, (snapshot) => {
            const dinos = snapshot.docs.map((doc) => {
                const result = {
                    id: doc.id,
                    item: doc.data(),
                };
                // Some older dinos might not have this field, and it creates issues, make sure it has it
                if (!('isActive' in result.item)) {
                    result.item.isActive = true;
                }
                // Just added enhancements, make sure it is "initialized" on old db entries
                if (!result.item.enhancements) {
                    result.item.enhancements = 0;
                }
                return result;
            });
            setPlayersDinos(dinos);
            //console.log('DINOS RETUREND', dinos);
        });
    }, [playerId]);

    const handleNewPlayer = (e) => {
        e.preventDefault();
        setNewPlayerIsOpen(true);
    };
    const handleSaveNewPlayer = (newPlayerName) => {
        const newPlayerInfo = addNewPlayer(newPlayerName);
        setPlayerId(newPlayerInfo.name);
        setNewPlayerIsOpen(false);
    };
    const handleCancelNewPlayer = () => {
        setNewPlayerIsOpen(false);
    };

    const [editingDinoInfo, setEditingDinoInfo] = useState(null);
    const handleEditDino = (dinoInfo) => {
        setEditingDinoInfo(dinoInfo);
    };
    const handleClearEdit = () => {
        setEditingDinoInfo(null);
    };

    const handleLogout = async () => {
        console.log('LOGOUT!');
        await firebaselogout();
        navigate('/');
    };

    return (
        <div className="App">
            <NewPlayerDialog
                isOpen={newPlayerIsOpen}
                onSave={handleSaveNewPlayer}
                onCancel={handleCancelNewPlayer}
            />
            <div className="logout">
                <Button variant="contained" color="secondary" onClick={handleLogout}>
                    Logout
                </Button>
            </div>
            <div className="app-left-column">
                <TopPlayerCount playerList={playerList} />
                <Progress progressLog={progressLog} />
            </div>
            <div className="app-right-column">
                <div className="app_title">
                    <h2>Dino Master App!</h2>
                </div>
                <div className="player_selection">
                    <PlayerSelector
                        playerList={playerList}
                        handleSelection={handleSelectPlayer}
                        selectedPlayer={selectedPlayer}
                    />
                    <Button variant="contained" color="primary" onClick={handleNewPlayer}>
                        New Player
                    </Button>
                    {selectedPlayer ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedPlayer.isTop100 ? true : false}
                                    onChange={updateTop100Status}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Is Top 100 Player"
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div className="player_dinolist">
                    <div className="dino-grid">
                        {playersDinos.map((dinoInfo) =>
                            dinoInfo.item.isActive ? (
                                <Dino
                                    key={dinoInfo.id}
                                    dinoInfo={dinoInfo}
                                    updateIsActive={updateIsActive}
                                    onEditDino={handleEditDino}
                                />
                            ) : (
                                ''
                            )
                        )}
                    </div>
                    <div className="dino-grid-inactive">
                        {playersDinos.map((dinoInfo) =>
                            dinoInfo.item.isActive ? (
                                ''
                            ) : (
                                <InactiveDino
                                    key={`${playerId}-${dinoInfo.id}`}
                                    dinoInfo={dinoInfo}
                                    updateIsActive={updateIsActive}
                                />
                            )
                        )}
                    </div>
                </div>
                <div className="add-dino">
                    {!playerId ? (
                        ''
                    ) : (
                        <AddDino
                            dinoList={dinoList}
                            playerId={playerId}
                            updateProgressLog={updateProgressLog}
                            editingDinoInfo={editingDinoInfo}
                            clearEdit={handleClearEdit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
export default App;
